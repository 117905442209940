import { selectAssignment } from '@redux/experiments/selectors/index';
import type { GuestCapacityFilterAssignment } from '@typings/experiments';
import type { State } from 'types/redux';

export const capacityFilterSelector = (state: State) => {
	if (state.category.code !== 'REC') {
		return null;
	}

	return selectAssignment(state, [
		'capacityFilter',
	]) as GuestCapacityFilterAssignment;
};
