import ExitEarlyAlert from '@components/auto-account-creation/exitEarlyAlert/ExitEarlyAlert';
import Breadcrumbs from '@components/shared/Breadcrumbs';
import { useListingsSections } from '@hooks/use-listings-sections';
import { useAppSelector } from '@redux/hooks';
import { Column, Row } from '@xo-union/tk-component-grid';
import { useAtomValue } from 'jotai';
import React, { type FC } from 'react';
import SpotlightAd from '../../SearchResults/components/SpotlightAd';
import AdStyles from '../../TopBar/styles.scss';
import { isSemanticSearchCalledAtom } from '../atoms';
import { SemanticSearch } from './SemanticSearch';
import { SemanticTitle } from './SemanticTitle';
import Styles from './styles.scss';

export interface SemanticTopBarProps {
	categoryCode: Category.CategoryCode;
	isBOW: boolean;
	location: Redux.Location;
	marketCode: string;
	shouldShowSpotlight: boolean;
}

export const SemanticTopBar: FC<SemanticTopBarProps> = (props) => {
	const {
		categoryCode,
		isBOW,
		location: { city, stateCode },
		marketCode,
		shouldShowSpotlight,
	} = props;
	const viewport = useAppSelector((state) => state.viewport);
	const isSemanticSearchCalled = useAtomValue(isSemanticSearchCalledAtom);
	const isListingsSections = useListingsSections();
	const lessThanSmall = viewport.lessThan.small;
	const row =
		isListingsSections && !isSemanticSearchCalled
			? Styles.listingsSectionsRow
			: Styles.semanticRow;

	return (
		<>
			{shouldShowSpotlight && (
				<Row>
					<Column xs="12">
						<SpotlightAd
							marketCode={marketCode}
							categoryCode={categoryCode}
							isBOW={isBOW}
							className={AdStyles.adBanner}
						/>
					</Column>
				</Row>
			)}
			{!lessThanSmall && (
				<Row className={row}>
					<Column className={Styles.semanticColumnTitle}>
						<Breadcrumbs
							categoryCode={categoryCode}
							city={city}
							stateCode={stateCode}
							isHeader
						/>
						<SemanticTitle lessThanSmall={lessThanSmall} />
					</Column>
					<Column className={Styles.semanticColumnSearch}>
						<SemanticSearch />
					</Column>
				</Row>
			)}
			{lessThanSmall && (
				<>
					<Row className={row}>
						<Column xs="12">
							<SemanticSearch />
						</Column>
					</Row>
					<Row>
						<Column xs="12">
							<SemanticTitle lessThanSmall={lessThanSmall} />
						</Column>
					</Row>
				</>
			)}
			<ExitEarlyAlert />
		</>
	);
};
