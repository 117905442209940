import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { semanticSearchV2Selector } from '@redux/experiments/selectors/semantic-search-v2';
import { useAppSelector } from '@redux/hooks';
import { selectIsMobile } from '@redux/viewport/selectors';
import { compose } from '@xo-union/react-css-modules';
import { Button } from '@xo-union/tk-component-buttons';
import { Field, Form, type PseudoEvent } from '@xo-union/tk-component-fields';
import { IconButton } from '@xo-union/tk-component-icons';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { debounce } from 'lodash';
import React, { useMemo, useRef } from 'react';
import AnalyticsEvents from '../../../../../../constants/analytics';
import {
	fieldValueAtom,
	isSemanticSearchCalledAtom,
	resetSemanticSearchResponseAtom,
} from '../atoms';
import { useSemanticSearch } from '../hooks/use-semantic-search';
import { useSemanticSuggestions } from '../hooks/use-semantic-suggestions';
import { MobileSemanticSuggestions } from './MobileSemanticSuggestions';
import { SemanticControls } from './SemanticControls';
import Styles from './SemanticSearch.styles.scss';
import { SemanticSuggestions } from './SemanticSuggestions';

const fieldClasses = compose({
	container: Styles.fieldContainer,
	input: Styles.fieldInput,
});

export const SemanticSearch = () => {
	const { handleSemanticSearch, trackSearchButtonClicked } =
		useSemanticSearch();
	const [fieldValue, setFieldValue] = useAtom(fieldValueAtom);
	const isMobile = useAppSelector((state) => selectIsMobile(state));
	const categoryCode = useAppSelector((state) => state.category.code);
	const stateCode = useAppSelector((state) => state.location.stateCode);
	const city = useAppSelector((state) => state.location.city);
	const semanticSearchV2Assignment = useAppSelector((state) =>
		semanticSearchV2Selector(state),
	);
	const isSemanticSearchCalled = useAtomValue(isSemanticSearchCalledAtom);
	const resetSemanticSearchResponse = useSetAtom(
		resetSemanticSearchResponseAtom,
	);
	const { getSuggestions, closeSuggestions, openSuggestions } =
		useSemanticSuggestions();
	const closeButtonRef = useRef<HTMLButtonElement>(null);

	const { track } = useAnalyticsContext();

	const onInputChange = useMemo(
		() =>
			debounce((searchString: string) => {
				track({
					event: AnalyticsEvents.VENDOR_SEARCH_INTERACTION,
					properties: {
						searchString,
						action: 'typing',
						sourceContent: 'free_search_box',
						sourcePage: isSemanticSearchCalled
							? AnalyticsEvents.SEMANTIC_RESULTS
							: AnalyticsEvents.CATEGORY_RESULTS,
						vendorCategoryCode: categoryCode,
						location: `${city}, ${stateCode}`,
					},
				});
			}, 1000),
		[categoryCode, track, isSemanticSearchCalled, city, stateCode],
	);

	const handleSearchTextChange = async (
		e: React.ChangeEvent<HTMLInputElement>,
	) => {
		const value = e.target.value;
		setFieldValue(value);
		onInputChange(value);
		getSuggestions(value);
	};

	const handleFieldClick = () => {
		if (
			document.activeElement === closeButtonRef?.current &&
			fieldValue.length > 0
		) {
			resetSemanticSearchResponse();
			closeSuggestions();
			setFieldValue('');
		}
	};

	const handleSubmit = (pseudoEvent: PseudoEvent) => {
		if (!pseudoEvent.valid) {
			return;
		}
		if (
			isMobile &&
			fieldValue.length > 0 &&
			semanticSearchV2Assignment === 'semantic-search-v2'
		) {
			openSuggestions();
			return;
		}
		trackSearchButtonClicked();
		handleSemanticSearch(fieldValue);
		closeSuggestions();

		if (document.activeElement instanceof HTMLElement) {
			document.activeElement.blur();
		}
	};

	const handleMobileOnClick = () => {
		if (fieldValue.length === 0) {
			openSuggestions();
		}
	};
	return (
		<div className={Styles.semanticSearchWrapper}>
			<SemanticControls />

			<div onClick={handleMobileOnClick} onKeyDown={handleMobileOnClick}>
				<Form className={Styles.semanticSearchForm} onSubmit={handleSubmit}>
					<Field
						classes={fieldClasses}
						label={
							semanticSearchV2Assignment === 'semantic-search-v2'
								? 'Search vendors, styles, details'
								: 'Search'
						}
						name="search..."
						onChange={handleSearchTextChange}
						onFocus={() => {
							if (fieldValue.length > 0 && !isMobile) {
								openSuggestions();
								getSuggestions(fieldValue);
							}
						}}
						validations={{ required: true }}
						value={fieldValue}
						subTextOnInvalid=""
						onClick={() => openSuggestions()}
					/>
					<IconButton<HTMLButtonElement>
						aria-label="clear search field"
						className={
							fieldValue.length > 0
								? Styles.clearButton
								: Styles.clearButtonHidden
						}
						name="close_circle"
						onClick={handleFieldClick}
						ref={closeButtonRef}
						size="md"
					/>
					<Button
						className={Styles.semanticSearchButton}
						color="primary"
						iconName="search"
						size="lg"
						type="submit"
						aria-label="Submit search"
					/>
				</Form>
			</div>
			{isMobile ? <MobileSemanticSuggestions /> : <SemanticSuggestions />}
		</div>
	);
};
