export function isRenameDressFacetExperimentEnabled(
	seoFilter: string,
	stateCode: string | null,
): boolean {
	const experimentSeoFilter = [
		'Bridesmaid Dresses',
		'Flower Girl Dresses',
		'Mother of the Bride Dresses',
		'Bridal Shower Dresses',
		'Wedding Dresses',
	];

	const experimentLocations = [
		'ca',
		'ny',
		'il',
		'mi',
		'va',
		'az',
		'ma',
		'wi',
		'mn',
		'al',
		'ky',
		'ok',
		'ut',
		'nv',
		'ks',
		'nm',
		'id',
		'hi',
		'me',
		'ri',
		'sd',
		'ak',
		'wy',
	];

	return (
		experimentSeoFilter.includes(String(seoFilter)) &&
		experimentLocations.includes(String(stateCode).toLowerCase())
	);
}

export function getSeoTextVariantDressFacet(dressType: string): string {
	return `${dressType} and Gowns`;
}
