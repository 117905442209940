import VendorResult from '@components/vendorResult/VendorResult';
import { useListingsSections } from '@hooks/use-listings-sections';
import { useAppSelector } from '@redux/hooks';
import { Column, type ColumnProps, Row } from '@xo-union/tk-component-grid';
import { useAtomValue } from 'jotai';
import React, {
	type FC,
	Fragment,
	useContext,
	useMemo,
	useEffect,
} from 'react';
import WeddingsContext from '../../../../../contexts/WeddingsContext/WeddingsContext';
import { ListingsSectionsResults } from '../../../components/ListingsSectionsResults';
import GridView from '../../GridView';
import Loading from '../../GridView/loading';
import {
	isErrorAtom,
	isFetchingAtom,
	isSemanticSearchCalledAtom,
	semanticSearchResponseAtom,
} from '../atoms';
import { useSemanticSearch } from '../hooks/use-semantic-search';
import { SemanticSearchErrorResults } from './SemanticSearchErrorResults';
import Styles from './styles.scss';

export interface DividerProps {
	index: number;
	vendorLength: number;
}

export const Divider: FC<DividerProps> = (props) => {
	const { index, vendorLength } = props;

	if (index === vendorLength - 1) {
		return null;
	}

	return (
		<div className={Styles.dividerWrapper} role="presentation">
			<div className={Styles.divider} />
		</div>
	);
};

export const SemanticSearchResults = () => {
	const { trackVendorCategoryViewed } = useSemanticSearch();
	const isFetching = useAtomValue(isFetchingAtom);
	const isError = useAtomValue(isErrorAtom);
	const isSemanticSearchCalled = useAtomValue(isSemanticSearchCalledAtom);
	const semanticSearchResponse = useAtomValue(semanticSearchResponseAtom);

	const { onSaveHandler, getIsSaved } = useContext(WeddingsContext);
	const isMobile = useAppSelector((state) => state.viewport.isMobile);
	const isListingsSections = useListingsSections();
	const columnSize: ColumnProps = { xs: '12', md: '6', lg: '3' };

	const maxImageCount = useMemo(() => {
		return isMobile ? 10 : 5;
	}, [isMobile]);

	useEffect(() => {
		if (isFetching && isSemanticSearchCalled) {
			trackVendorCategoryViewed();
		}
	}, [isFetching, isSemanticSearchCalled, trackVendorCategoryViewed]);

	if (isFetching) {
		return <Loading overrideColumnSize={columnSize} />;
	}

	if (!isSemanticSearchCalled) {
		// <GridView /> was where <SemanticSearchResults /> currently is
		return <GridView />;
	}

	if (isError) {
		return <SemanticSearchErrorResults />;
	}

	if (semanticSearchResponse.storefronts.length === 0) {
		return null;
	}

	if (isListingsSections) {
		return (
			<ListingsSectionsResults
				results={semanticSearchResponse.storefronts}
				maxImageCount={maxImageCount}
			/>
		);
	}

	return (
		<Row>
			{semanticSearchResponse.storefronts.map((v, idx) => {
				return (
					<Fragment key={v.id}>
						<Column {...columnSize} className={Styles.resultsColumn}>
							<VendorResult
								impressionType="Directory: Main"
								index={idx}
								isSaved={getIsSaved(v.id)}
								maxImageCount={maxImageCount}
								onSaveHandler={onSaveHandler}
								rawVendor={v}
								cardUiLocation="Semantic Search"
								key={v.id}
							/>
						</Column>
						<Divider
							index={idx}
							vendorLength={semanticSearchResponse.storefronts.length}
						/>
					</Fragment>
				);
			})}
		</Row>
	);
};
