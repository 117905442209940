import { semanticSearch } from '@api/semanticSearch';
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { semanticSearchV2Selector } from '@redux/experiments/selectors/semantic-search-v2';
import { useAppSelector } from '@redux/hooks';
import { Logger } from '@utils/logger/logger';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import AnalyticsEvents from '../../../../../../constants/analytics';
import {
	fieldValueAtom,
	isSemanticSearchCalledAtom,
	resetSemanticSearchResponseAtom,
	semanticSearchResponseAtom,
	storefrontsCountAtom,
	updateIsFetchingAtom,
} from '../atoms';
import { hydrateVendors } from '../utils';
import { useLocationValues } from './use-location-values';

export const useSemanticSearch = () => {
	const fieldValue = useAtomValue(fieldValueAtom);
	const isSemanticSearchCalled = useAtomValue(isSemanticSearchCalledAtom);
	const storefrontsCount = useAtomValue(storefrontsCountAtom);
	const categoryCode = useAppSelector((state) => state.category.code);
	const marketCode = useAppSelector((state) => state.location.marketCode);
	const stateCode = useAppSelector((state) => state.location.stateCode);
	const member = useAppSelector((state) => state.membership.member);
	const city = useAppSelector((state) => state.location.city);
	const pageNumber = useAppSelector((state) => state.search.pagination.page);
	const semanticSearchV2Assignment = useAppSelector((state) =>
		semanticSearchV2Selector(state),
	);
	const { track } = useAnalyticsContext();
	const setIsFetching = useSetAtom(updateIsFetchingAtom);
	const locationValues = useLocationValues();
	const setSemanticSearchResponse = useSetAtom(semanticSearchResponseAtom);
	const resetSemanticSearchResponse = useSetAtom(
		resetSemanticSearchResponseAtom,
	);
	const trackSearchButtonClicked = useCallback(() => {
		const baseProperties = {
			product: 'marketplace',
			searchTerm: fieldValue,
			sourcePage: isSemanticSearchCalled
				? AnalyticsEvents.SEMANTIC_RESULTS
				: 'category results',
			sourceContent: 'free_search_box',
			action: 'search_completed',
			vendorCategoryCode: categoryCode,
			location: `${city}, ${stateCode}`,
		};
		const properties =
			semanticSearchV2Assignment === 'semantic-search-v2'
				? { ...baseProperties, selection: 'free_text' }
				: baseProperties;
		track({
			event: AnalyticsEvents.VENDOR_SEARCH_INTERACTION,
			properties,
		});
	}, [
		categoryCode,
		fieldValue,
		isSemanticSearchCalled,
		semanticSearchV2Assignment,
		track,
		city,
		stateCode,
	]);

	const trackVendorCategoryViewed = useCallback(() => {
		const properties = {
			displayLocationName: `${city}, ${stateCode}`,
			displayMarketCode: marketCode,
			memberId: member || '',
			pageNumber,
			searchTerm: fieldValue,
			vendorCategoryCode: categoryCode,
			vendorCategoryLevel: 'semantic',
			vendorResultsCount: storefrontsCount,
		};

		track({
			event: AnalyticsEvents.VENDOR_CATEGORY_VIEWED,
			properties,
		});
	}, [
		categoryCode,
		city,
		fieldValue,
		marketCode,
		member,
		pageNumber,
		stateCode,
		storefrontsCount,
		track,
	]);

	const handleSemanticSearch = useCallback(
		async (searchTerm: string) => {
			setIsFetching(true);

			try {
				const semanticSearchResponse = await semanticSearch({
					style_description: searchTerm,
					vendor_category: categoryCode,
					included_vendor_tiers: 'paid-only',
					exclude_storefronts_without_image: true,
					exclude_unclaimed_venues: true,
					limit: 80,
					include_venue_desc: false,
					...locationValues,
				});
				const ids = semanticSearchResponse?.response?.storefronts?.map(
					(s) => s.id,
				);
				const vendors = await hydrateVendors(ids);
				setSemanticSearchResponse({
					isFetching: false,
					isSemanticSearchCalled: true,
					response_id: semanticSearchResponse?.response?.response_id,
					searchFieldValue: searchTerm,
					storefronts: vendors,
					storefrontsCount: vendors.length,
					isError: false,
				});
			} catch (error) {
				setSemanticSearchResponse({
					isFetching: false,
					isSemanticSearchCalled: true,
					response_id: '',
					searchFieldValue: searchTerm,
					storefronts: [],
					storefrontsCount: 0,
					isError: true,
				});
				Logger.error({
					log_message: 'Error performing semantic search',
					newRelic: true,
					errorToLog: error,
				});
			}
		},
		[categoryCode, locationValues, setIsFetching, setSemanticSearchResponse],
	);

	return {
		handleSemanticSearch,
		resetSemanticSearchResponse,
		trackSearchButtonClicked,
		trackVendorCategoryViewed,
	};
};
